import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import "../css/error.css"
import SEO from "../components/SEO"

const error = () => {
  return (
    <Layout>
      <SEO
        title="Error 404"
        description="You have arrived on the wrong side."
      />
      <main className="error-page noSelect">
        <div className="error-container noSelect">
          <h1>"oops it's a dead end"</h1>
          <Link to="/" className="btn bb">
            Back Home
          </Link>
        </div>
      </main>
    </Layout>
  )
}

export default error
